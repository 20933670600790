import { Button, CardMedia, Theme, Typography, useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { graphql, HeadProps, Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { NewsArticleQueryQuery } from '../../graphql-types';
import { IconFacebook } from '../assets/icons/social/facebook';
import { IconLinkedIn } from '../assets/icons/social/linkedin';
import { IconTwitter } from '../assets/icons/social/twitter';
import { CAPTIONS_TEXT_COLOR, LL_BACKGROUND_COLOR } from '../colors';
import { BackButton } from '../components/back-button';
import { ExternalLink } from '../components/external-link';
import Layout from '../components/layout';
import { NavbarBreadcrumbs } from '../components/navbar-breadcrumbs';
import { NewsCard } from '../components/news-card';
import { Absolute } from '../components/primitives/absolute';
import { FlexBox } from '../components/primitives/flex';
import { Width80Box } from '../components/primitives/Width80Box';
import SEO from '../components/SEO';
import { linkStyle } from '../constants';
import { LinkItem } from '../interfaces/link';
import { formatDateTimeWithMonthName } from '../utils/formatters';

interface NewArticleProps {
  location: any;
  data: NewsArticleQueryQuery;
}
const homeBreadcrumb = { label: 'Home', value: 'home', href: '/' };

const ARTICLE_ANCHOR_COLOR = '#3397ff';

export const articleSxProps: SxProps = {
  mt: '25px',
  maxWidth: '1024px',
  lineHeight: '25px',
  color: '#151f35',
  '& a': {
    color: ARTICLE_ANCHOR_COLOR,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  '& p': {
    lineHeight: '24px',
    mb: '1em',
    '& strong': {
      fontWeight: 500
    }
  },
  '& h1,h2,h3,h4,h5,h6': {
    lineHeight: '24px',
    margin: '30px 0 25px',
    fontWeight: 600
  },
  '& ul,ol': {
    pl: '2em',
    margin: '1em 0'
  },
  '& ul': {
    '& li': {
      pl: '12px',
      '::marker': {
        content: "'>'",
        color: 'primary.main',
        fontSize: '14px',
        fontWeight: 700
      }
    }
  },
  '& li': {
    mb: '10px',
    '& strong': {
      fontWeight: 500
    },
    '::first-letter': {
      textTransform: 'capitalize'
    }
  },
  '& img': {
    maxWidth: '1024px',
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    objectPosition: 'left',
    mx: 'auto',
    my: '20px'
  },
  '& figure': {
    marginBottom: '1em'
  },
  '& figcaption': {
    display: 'block',
    opacity: '.6',
    fontSize: '12px',
    fontStyle: 'italic',
    textAlign: 'left',
    mt: '-10px'
  },
  '& table': {
    maxWidth: '1024px',
    width: '100%',
    overflowX: 'auto',
    borderCollapse: 'collapse',
    my: '20px'
  },
  '& iframe': {
    width: '100%',
    mt: '20px'
  },
  '& div': {
    mt: '20px'
  }
};

export function Head(props: HeadProps<NewsArticleQueryQuery>) {
  const { data } = props;
  const {
    CraftGQL: { article: entry }
  } = data;
  const { seo } = entry as { seo: CraftGql_Ether_SeoData };
  return <SEO pageUrl={props.location.pathname} seo={seo} />;
}

const newsArticle = ({ data, location }: NewArticleProps) => {
  const theme: Theme = useTheme();
  const minBreadcrumbValue = 2;
  const [breadcrumbs, setBreadcrumbs] = React.useState<LinkItem[]>([]);

  /********************* STYLE *********************/
  const sxProps: { [className: string]: SxProps } = {
    articleWrapper: {
      padding: '20px',
      marginX: 'auto',
      maxWidth: '960px',
      [theme.breakpoints.down('md')]: {
        paddingY: '10px',
        width: '100%'
      }
    },
    articleTags: {
      mt: '20px',
      mx: 'auto',
      pb: '8px',
      width: 'fit-content'
    },
    articleTitle: {
      color: 'primary.main',
      marginTop: '28px',
      paddingX: '40px',
      textAlign: 'center'
    },
    articlePostDate: {
      color: CAPTIONS_TEXT_COLOR,
      marginY: '32px',
      textAlign: 'center'
    },
    articleImg: {
      borderRadius: '24px 24px 24px 24px',
      maxHeight: '528px',
      maxWidth: '952px',
      mx: 'auto',
      width: '100%'
    },
    articleSubTitle: {
      color: 'primary.main',
      marginTop: { xs: '25px', md: '76px' },
      marginBottom: { xs: '25px', md: '40px' }
    },
    articleShareContainer: {
      alignItems: 'center',
      display: 'flex',
      mt: '60px',
      mx: 'auto',
      width: 'fit-content'
    },
    articleButton: {
      alignItems: 'baseline',
      display: 'flex',
      color: theme.palette.primary.main,
      fontSize: '20px',
      fontWeight: 500,
      marginTop: '52px',
      marginX: 'auto',
      textTransform: 'none',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px'
      }
    },
    tagStyle: {
      display: 'block',
      border: '2px solid' + theme.palette.primary.main,
      borderRadius: '100px',
      color: 'secondary.main',
      fontWeight: 500,
      marginRight: '12px',
      padding: '12px 26px',
      whiteSpace: 'nowrap',
      '&:hover': {
        bgcolor: 'primary.main',
        color: 'white'
      }
    },
    backButton: {
      my: '20px',
      [theme.breakpoints.down('sm')]: {
        mt: '15px',
        mb: '10px'
      }
    },
    arrow: { fontFamily: 'Inter', marginRight: '4px' },
    lastSectionHeading: {
      color: 'primary.main',
      marginBottom: '48px',
      textAlign: 'center'
    },
    relatedArticlesContainer: {
      columnGap: '60px',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      justifyContent: 'center',
      marginTop: '20px',
      paddingX: '15px',

      [theme.breakpoints.down('md')]: {
        columnGap: '16px',
        display: 'flex',
        justifyContent: 'flex-start',
        overflowX: 'auto'
      }
    },
    newsLetterSection: {
      mx: 'auto',
      maxWidth: '990px'
    },
    offsetSection: {
      zIndex: -1,
      bgcolor: LL_BACKGROUND_COLOR,
      width: '100%'
    },
    articleDescription: articleSxProps
  };
  /********************* END STYLE *********************/

  useEffect(() => {
    if (location) {
      let { pathname } = location;
      let segments = pathname.split('/');
      if (segments[1]) segments = segments.slice(1);
      else segments = segments.slice(2);

      let computedBreadcrumbs = segments.map((segment, index) => {
        let obj: LinkItem = {} as LinkItem;
        obj.value = segment;
        obj.href = '';
        Array.from({ length: index + 1 }).map((_, i) => {
          let tmp = i === index && segments[i] === 'news' ? 'latest-news' : segments[i];
          obj.href = obj.href + `/${tmp}`;
        });

        segment = segment.replace(/-/g, ' ');
        obj.label = segment;

        return obj;
      });
      setBreadcrumbs([homeBreadcrumb, ...computedBreadcrumbs]);
    }
  }, []);

  const { article: queryData, latestArticles } = data.CraftGQL;
  console.log({ data });

  /**************** Social Share ***************/
  let hashtags = '';
  queryData?.newsTags.map((tag) => (hashtags = hashtags.concat(',' + tag.slug.replace(/-/, ''))));
  hashtags = hashtags.concat(',ListingLoop');
  const facebookShareLink = `https://www.facebook.com/sharer.php?u=${location.href}`;
  const twitterShareLink = `https://twitter.com/share?url=${location.href}&text=${queryData?.title}&hashtags=${hashtags}`;
  const linkedinShareLink = `https://www.linkedin.com/shareArticle?mini=true&url=${location.href}&title=${queryData?.title}&source=Listing%20Loop`;
  /***************** END Social Share ***************/

  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    setRelatedArticles([].concat(queryData?.relatedArticles || []).concat(latestArticles || []));
  }, [data]);

  let richText = queryData.richTextContent;

  richText = richText.replace(/<table /, '<div style="overflow-x:auto"><table ');
  richText = richText.replace(/<\/table>/, '</table></div>');

  const bgImage =
    (queryData?.heroBackgroundImage?.length > 0 && queryData?.heroBackgroundImage[0]?.url) ?? '';

  return (
    <Box>
      <Box sx={sxProps.articleWrapper}>
        <BackButton href="/" sx={sxProps.backButton} />
        {breadcrumbs.length > minBreadcrumbValue && (
          <Box px="1px">
            <NavbarBreadcrumbs breadcrumbs={breadcrumbs} />
          </Box>
        )}
        <Box width="100%" sx={{ overflowX: 'auto' }}>
          <FlexBox sx={sxProps.articleTags}>
            {queryData?.newsTags?.map((tag) => (
              <Link to={`/category/${tag.slug}/`} style={linkStyle}>
                <Typography sx={sxProps.tagStyle} component="span" variant="body1">
                  {tag.title}
                </Typography>
              </Link>
            ))}
          </FlexBox>
        </Box>
        <Typography sx={sxProps.articleTitle} variant="h2">
          {queryData?.title}
        </Typography>
        <Typography sx={sxProps.articlePostDate} variant="body2">
          {formatDateTimeWithMonthName(Date.parse(queryData?.postDate))}
        </Typography>
        <CardMedia
          alt="News Image"
          component="img"
          sizes="(max-width: 951px) 60vw, 952px"
          srcSet={`
             ${bgImage.replace(
               'https://images.listingloop.com.au/',
               'https://images.listingloop.com.au/cdn-cgi/image/format=auto,fit=cover,width=320/'
             )}   320w,
             ${bgImage.replace(
               'https://images.listingloop.com.au/',
               'https://images.listingloop.com.au/cdn-cgi/image/format=auto,fit=cover,width=640/'
             )}   640w,
             ${bgImage.replace(
               'https://images.listingloop.com.au/',
               'https://images.listingloop.com.au/cdn-cgi/image/format=auto,fit=cover,width=960/'
             )}   960w,
             ${bgImage.replace(
               'https://images.listingloop.com.au/',
               'https://images.listingloop.com.au/cdn-cgi/image/format=auto,fit=cover,width=1280/'
             )} 1280w`}
          image={
            (queryData?.heroBackgroundImage?.length > 0 &&
              queryData?.heroBackgroundImage[0]?.url) ??
            ''
          }
          sx={sxProps.articleImg}
        />
        {/* <Typography sx={sxProps.articleSubTitle} variant="h5">
          {queryData?.textSubtitle}
        </Typography> */}
        <Box
          id="articleDescription"
          dangerouslySetInnerHTML={{ __html: richText }}
          color={theme.palette.secondary.main}
          sx={sxProps.articleDescription}
        />

        <Box sx={sxProps.articleShareContainer}>
          <Typography color="secondary.main" fontSize="18px">
            Share
          </Typography>
          <ExternalLink href={facebookShareLink}>
            <IconFacebook
              color={theme.palette.secondary.main}
              height="18px"
              marginLeft="32px"
              width="8px"
            />
          </ExternalLink>
          <ExternalLink href={twitterShareLink}>
            <IconTwitter
              color={theme.palette.secondary.main}
              height="15px"
              marginLeft="32px"
              width="18px"
            />
          </ExternalLink>
          <ExternalLink href={linkedinShareLink}>
            <IconLinkedIn
              color={theme.palette.secondary.main}
              height="18px"
              marginLeft="32px"
              width="18px"
            />
          </ExternalLink>
        </Box>
        {queryData?.buttonLink && (
          <Button sx={sxProps.articleButton} onClick={() => window.open(queryData?.buttonLink)}>
            {queryData?.buttonTitle}{' '}
            <Typography component="span" sx={sxProps.arrow}>
              →
            </Typography>
          </Button>
        )}
      </Box>
      <Box bgcolor={LL_BACKGROUND_COLOR} py="42px" mt="50px">
        {relatedArticles?.length > 0 && (
          <>
            <Typography sx={sxProps.lastSectionHeading} variant="h3">
              You might be interested in
            </Typography>
            <Width80Box sx={sxProps.relatedArticlesContainer}>
              {relatedArticles?.map((props, index) => {
                const date = Date.parse(props.postDate);
                return (
                  index < 3 && (
                    <Link to={`/news/${props.slug}/`} style={linkStyle}>
                      <NewsCard
                        customCardStyle={{ background: 'transparent' }}
                        key={date}
                        imageLink={
                          (props.heroBackgroundImage?.length > 0 &&
                            props.heroBackgroundImage[0]?.url) ??
                          ''
                        }
                        date={date}
                        title={props.title}
                        slug={props.slug}
                      />
                    </Link>
                  )
                );
              })}
            </Width80Box>
          </>
        )}
      </Box>
      <Box bgcolor={LL_BACKGROUND_COLOR} width="100%">
        <Box position="relative">
          <Absolute sx={sxProps.offsetSection} />
        </Box>
      </Box>
    </Box>
  );
};

export const newsArticleQuery = graphql`
  query NewsArticleQuery($slug: String = "") {
    CraftGQL {
      latestArticles: entries(
        orderBy: "postDate"
        inReverse: true
        section: "mlLatestNews"
        limit: 3
        site: "movingLoop"
      ) {
        ... on CraftGQL_mlLatestNews_default_Entry {
          slug
          title
          postDate
          heroBackgroundImage {
            url
          }
          newsTags {
            title
          }
        }
      }
      article: entry(slug: [$slug], site: "movingLoop") {
        ... on CraftGQL_mlLatestNews_default_Entry {
          id
          slug
          newsTags {
            title
            slug
          }
          title
          postDate
          heroBackgroundImage {
            url
          }
          richTextContent
          buttonTitle
          openInNewTab
          seo {
            title
            description
          }
          relatedArticles {
            slug
            title
            postDate
            heroBackgroundImage {
              url
            }
            newsTags {
              title
              slug
            }
          }
        }
      }
    }
  }
`;

// const breadQuery = graphql`
//   query getBreadCrumbs {
//     allCraftGqlNavigationHeaderDefaultEntry(filter: { remoteParent: { id: { eq: null } } }) {
//       nodes {
//         title
//         navigationLink
//         remoteChildren {
//           ... on CraftGQL_navigationHeader_default_Entry {
//             title
//             navigationLink
//           }
//         }
//       }
//     }
//   }
// `;

newsArticle.Layout = Layout;
newsArticle.isNavbarBackgroundEmpty = true;

export default newsArticle;
